import React from 'react';
import {  BeeJusticeLogoWhite } from '../../Asset/imgIndex';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import MyVerticallyCenteredModal from './Modal';
import './footer.scss';

function Footer() {
    const [modalShow, setModalShow] = React.useState(false);
    return (
        <>
            <footer className="footer">
                <Container fluid>
                    <Row className="w-100">
                        <Col lg={11} className="mx-auto">
                            <Row>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                    <div className="footer_inner">
                                        <Image className="img-fluid" src={BeeJusticeLogoWhite} />
                                        <p>Bee Justice will be carrying the mantle to address different causes and glitches in the world by introducing acts of charity, altruism, and philanthropy into the digital world while investing in cryptocurrencies and blockchain technology.</p>
                                    </div>
                                    <div className="footer_links">
                                        <NavLink to="#" className="link_1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                                <path d="M3.79175 21.6668C3.35841 21.6668 2.97925 21.5043 2.65425 21.1793C2.32925 20.8543 2.16675 20.4752 2.16675 20.0418V5.9585C2.16675 5.52516 2.32925 5.146 2.65425 4.821C2.97925 4.496 3.35841 4.3335 3.79175 4.3335H22.2084C22.6417 4.3335 23.0209 4.496 23.3459 4.821C23.6709 5.146 23.8334 5.52516 23.8334 5.9585V20.0418C23.8334 20.4752 23.6709 20.8543 23.3459 21.1793C23.0209 21.5043 22.6417 21.6668 22.2084 21.6668H3.79175ZM13.0001 13.4877L22.2084 7.44808V5.9585L13.0001 11.8627L3.79175 5.9585V7.44808L13.0001 13.4877Z" fill="white" />
                                            </svg>
                                            <span>
                                                contact@beejustice.com
                                            </span>
                                        </NavLink>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                    <div className="navLinks">
                                        <ul>
                                            <li>
                                                <Nav.Link href="#projects">Projects</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="#roadMap">Roadmap</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="#team">Team</Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="https://www.dropbox.com/s/yf8xuvwwm2mggas/Bee%20Justice%20WhitePaper%20%28v1.0%29.pdf?dl=0" target="_blank">Whitepaper</Nav.Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="icons_social">
                                        <ul>
                                            <li>
                                                <Nav.Link href="https://linktr.ee/beejustice" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27" viewBox="0 0 34 27" fill="none">
                                                        <path d="M13.0349 0.721169C12.7826 0.28569 12.304 0.015625 11.7847 0.015625C11.2653 0.015625 10.7868 0.28569 10.5345 0.721169L1.18963 16.9831C0.755231 17.8182 1.40683 18.7567 2.3851 18.7567H8.68915V24.9057C8.68915 25.5325 9.23126 26.0542 9.88286 26.0542H13.577C14.2304 26.0542 14.7725 25.5325 14.7725 24.9074V18.7567H13.0349C12.3322 18.7646 11.7302 18.276 11.6222 17.6099C11.6222 17.4015 11.6222 17.1931 11.7299 16.9831L16.9462 7.91368L13.0349 0.721169Z" fill="#32B48C" />
                                                        <path d="M21.2803 0.721112C21.5329 0.286485 22.011 0.0170898 22.5297 0.0170898C23.0484 0.0170898 23.5264 0.286485 23.779 0.721112L33.1238 16.983C33.5582 17.8182 32.9066 18.7566 31.9284 18.7566H25.7356V24.9056C25.7356 25.5324 25.1917 26.0541 24.5383 26.0541H20.627C19.9691 26.0486 19.4373 25.5384 19.4315 24.9073V18.7566H21.1709C21.8735 18.7646 22.4755 18.2759 22.5835 17.6098C22.5835 17.4014 22.5835 17.1931 22.474 16.983L17.2595 7.91532L21.2803 0.721112Z" fill="#32B48C" />
                                                    </svg>
                                                </Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="https://discord.gg/QaShp2GG6t" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27" viewBox="0 0 34 27" fill="none">
                                                        <path d="M28.5113 2.18205C26.3326 1.16268 24.003 0.421839 21.5674 0C21.2683 0.540782 20.9189 1.26815 20.6779 1.84676C18.0889 1.4574 15.5237 1.4574 12.9823 1.84676C12.7414 1.26815 12.384 0.540782 12.0822 0C9.64407 0.421839 7.3118 1.1654 5.13307 2.18744C0.738566 8.82812 -0.452712 15.3038 0.142928 21.6876C3.0576 23.8642 5.88226 25.1864 8.65925 26.0517C9.34491 25.108 9.95642 24.1049 10.4832 23.0477C9.47991 22.6664 8.51895 22.196 7.61095 21.6498C7.85184 21.4713 8.08747 21.2848 8.31511 21.0928C13.8532 23.6831 19.8705 23.6831 25.3425 21.0928C25.5728 21.2848 25.8084 21.4713 26.0466 21.6498C25.136 22.1987 24.1724 22.6691 23.1691 23.0504C23.6959 24.1049 24.3048 25.1107 24.993 26.0543C27.7727 25.1891 30.6 23.867 33.5147 21.6876C34.2136 14.2872 32.3207 7.87094 28.5113 2.18205ZM11.2377 17.7617C9.57523 17.7617 8.21185 16.2096 8.21185 14.3196C8.21185 12.4296 9.54611 10.8749 11.2377 10.8749C12.9294 10.8749 14.2927 12.4269 14.2636 14.3196C14.2662 16.2096 12.9294 17.7617 11.2377 17.7617ZM22.4199 17.7617C20.7574 17.7617 19.394 16.2096 19.394 14.3196C19.394 12.4296 20.7282 10.8749 22.4199 10.8749C24.1115 10.8749 25.4749 12.4269 25.4457 14.3196C25.4457 16.2096 24.1115 17.7617 22.4199 17.7617Z" fill="#32B48C" />
                                                    </svg>
                                                </Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="https://t.me/beejusticenft" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="27" viewBox="0 0 32 27" fill="none">
                                                        <path d="M1.93181 11.3467C1.93181 11.3467 15.868 5.62727 20.7014 3.61337C22.5541 2.80781 28.8375 0.230016 28.8375 0.230016C28.8375 0.230016 31.7375 -0.897768 31.4959 1.84114C31.4153 2.96892 30.7709 6.91616 30.1264 11.1856C29.1597 17.2273 28.1125 23.8329 28.1125 23.8329C28.1125 23.8329 27.9514 25.6857 26.5819 26.0079C25.2125 26.3302 22.9569 24.8802 22.5541 24.5579C22.2319 24.3163 16.5124 20.6912 14.418 18.919C13.8541 18.4357 13.2097 17.469 14.4985 16.3412C17.3986 13.6829 20.8625 10.3801 22.9569 8.28562C23.9236 7.31895 24.8903 5.06338 20.8625 7.80228C15.143 11.7495 9.50407 15.4551 9.50407 15.4551C9.50407 15.4551 8.21518 16.2607 5.7985 15.5357C3.38182 14.8107 0.562357 13.844 0.562357 13.844C0.562357 13.844 -1.37099 12.6356 1.93181 11.3467Z" fill="#32B48C" />
                                                    </svg>
                                                </Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="https://twitter.com/BEEJUSTICENFT" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="25" viewBox="0 0 34 25" fill="none">
                                                        <path d="M33.2609 2.88715C32.0367 3.37778 30.7217 3.70793 29.3414 3.85685C30.751 3.09494 31.832 1.8886 32.3411 0.450216C31.0235 1.15555 29.5627 1.66811 28.0071 1.94516C26.7624 0.748051 24.9895 0 23.0271 0C19.2586 0 16.2038 2.75786 16.2038 6.15756C16.2038 6.6401 16.2652 7.1111 16.3803 7.56017C10.7096 7.30388 5.68228 4.85194 2.31538 1.12554C1.72951 2.03521 1.39306 3.09263 1.39306 4.2228C1.39306 6.35843 2.59681 8.24356 4.42737 9.34717C3.30933 9.31485 2.25654 9.03779 1.33679 8.57719C1.33679 8.60374 1.33679 8.62798 1.33679 8.65453C1.33679 11.6386 3.68798 14.1276 6.81055 14.692C6.23873 14.8329 5.63494 14.908 5.01196 14.908C4.57319 14.908 4.14466 14.8687 3.72891 14.7982C4.59751 17.2444 7.11756 19.0257 10.1033 19.0753C7.76868 20.7273 4.82648 21.712 1.62844 21.712C1.07838 21.712 0.534712 21.6831 0 21.6253C3.02023 23.372 6.60588 24.3913 10.4601 24.3913C23.0118 24.3913 29.8735 15.0083 29.8735 6.87098C29.8735 6.60432 29.8671 6.33881 29.8543 6.07445C31.1885 5.20519 32.3463 4.1212 33.2609 2.88715Z" fill="#32B48C" />
                                                    </svg>
                                                </Nav.Link>
                                            </li>
                                            <li>
                                                <Nav.Link href="https://www.instagram.com/beejusticenft/" target="_blank">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                        <path d="M20.3718 0H6.7906C3.05611 0 0 3.05611 0 6.7906V20.3724C0 24.106 3.05611 27.163 6.7906 27.163H20.3718C24.1062 27.163 27.1624 24.106 27.1624 20.3724V6.7906C27.1624 3.05611 24.1062 0 20.3718 0ZM24.8987 20.3724C24.8987 22.868 22.8688 24.8994 20.3718 24.8994H6.7906C4.29472 24.8994 2.26365 22.868 2.26365 20.3724V6.7906C2.26365 4.29439 4.29472 2.26365 6.7906 2.26365H20.3718C22.8688 2.26365 24.8987 4.29439 24.8987 6.7906V20.3724Z" fill="#32B48C" />
                                                        <path d="M20.9374 7.92264C21.8749 7.92264 22.635 7.16258 22.635 6.22499C22.635 5.28741 21.8749 4.52734 20.9374 4.52734C19.9998 4.52734 19.2397 5.28741 19.2397 6.22499C19.2397 7.16258 19.9998 7.92264 20.9374 7.92264Z" fill="#32B48C" />
                                                        <path d="M13.5814 6.79053C9.83023 6.79053 6.79077 9.83031 6.79077 13.5811C6.79077 17.3306 9.83023 20.3724 13.5814 20.3724C17.3315 20.3724 20.372 17.3306 20.372 13.5811C20.372 9.83031 17.3315 6.79053 13.5814 6.79053ZM13.5814 18.1087C11.0814 18.1087 9.05441 16.0817 9.05441 13.5811C9.05441 11.0805 11.0814 9.05418 13.5814 9.05418C16.0813 9.05418 18.1083 11.0805 18.1083 13.5811C18.1083 16.0817 16.0813 18.1087 13.5814 18.1087Z" fill="#32B48C" />
                                                    </svg>
                                                </Nav.Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col> 
                            </Row>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="footer_below">
                                <p>© 2022 Bee Justice All rights reserved
                                    <span>|</span>
                                    <span>
                                        <NavLink to="#" onClick={() => setModalShow(true)}>Privacy Policy</NavLink>
                                        <MyVerticallyCenteredModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                        />
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    )
}

export default Footer;