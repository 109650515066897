import React from 'react';
import { Bimage_1, Bimage_2, Bimage_3, Bimage_4, Bimage_5, Savesoil1, Savesoil2, Savesoil3, Savesoil4, Savesoil5, Savesoil6, Savesoil7 } from '../../Asset/imgIndex';
import {Image} from 'react-bootstrap';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

function Sliderr() {
    return (
        <>

            <Slider {...settings}>
                <div>

                    <Image className="img-fluid" src={Savesoil1} />

                </div>
                <div>

                    <Image className="img-fluid" src={Savesoil2} />

                </div>
                <div>

                    <Image className="img-fluid" src={Savesoil3} />

                </div>
                <div>

                    <Image className="img-fluid" src={Savesoil5} />

                </div>
                <div>

                    <Image className="img-fluid" src={Savesoil6} />

                </div>
                <div>

                    <Image className="img-fluid" src={Savesoil7} />

                </div>
            </Slider>

        </>
    )
}

export default Sliderr;