import React from 'react';
import Header from '../../Component/Header/header';
import Bees from '../../Asset/Img/Bee Justice Logo Animation_Horizontal.mp4';
import mobileBees from '../../Asset/Img/mobile_view.mp4';
import { Meet1, Meet2, Meet3, Meet4, Meet5, Meet6, Meet7, Meet8, Meet9, Meet10, team2, team3, team4, team5, productImage, BeeJusticeLogo, Team01, Team02, Team03 } from '../../Asset/imgIndex';
import Footer from '../../Component/Footer/footer';
import {NavLink} from 'react-router-dom';
import { Container, Row, Col, Image, Nav} from 'react-bootstrap';
import Sliderr from './slider';
import './homepage.scss';


function Homepage() {
	return (
		<>
			<Header />

			<section className="bee_justice">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<Row>
								<Col lg={12} xs={12}>
									<div className="second_sec">
										<Row>
											<Col xl={6} lg={6} sm={12} xs={12}>
												<Image className="img-fluid img-logo_bee" src={BeeJusticeLogo}/>
												<p><b>Bee Justice</b> is a DAO that generates various NFT projects that bridge <b>philanthropic initiatives</b> with the latest virtual trend on the blockchain.</p>
												<p>Unlike most projects which are focused on selling for profit, Bee Justice is a brand with a digital empowerment purpose: to contribute both to charities or causes that seek funds, and to the people who want to invest and/or help. All this by creating a platform where people buy NFTs while knowing that their investment is not just for personal gains, but for a bigger cause.</p>
												<p>We thought of NFT projects that would have an impact beyond the trend and the arts.</p>
											</Col>
											<Col xl={6} lg={6} sm={12} xs={12}>
												<div className="bee_s">
													<Image className="img-fluid" src={productImage} />
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="gif_banner">
				<Container fluid>
					<Row className="mx-auto w-100">
						<Col lg={11} className="mx-auto">
							<div className="bess_video">
								<video
									loop
									src={Bees}
									className="web_video"
									autoPlay
									playsInline
									muted
									controls
								></video>
								<video
									loop
									src={mobileBees}
									className="mobile_video"
									autoPlay
									playsInline
									muted
									controls
								></video>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="turkey_earthquake">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="heading">
								<h4>Ground zero 1 - Donations</h4>
							</div>
							<p><b>The earthquake</b> that struck Turkey and northern Syria was of an epic scale, leaving behind immense destruction, and unimaginable suffering. People in and around the area need <b>urgent</b> care, relief, funds, and equipment. Bee Justice will partake in relief efforts by launching GZ1.</p>
							<div className="button_load_mint">
								<Nav.Link className="button" href="https://mint.beejustice.com/">Learn more & Donate</Nav.Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="our_goals">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="heading">
								<h4>Our Goals</h4>
								<p className="mb-0">Bee Justice will be carrying the mantle to address different causes and glitches in the world by introducing acts of charity, altruism, and philanthropy into the digital world while investing in cryptocurrencies and blockchain technology.</p>
								<p className="m-0"><b>We are transparent, eco-sustainable, and collective-led.</b></p>
								<p className="mt-0">That’s why we chose Ethereum Blockchain.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="our_core_values">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="heading">
								<h4>Our Core Values</h4>
							</div>
							<Row>
								<Col lg={3} md={6} sm={12} xs={12}>
									<div className="goal_types voilent">
										<h6>Human-centered</h6>
									</div>
								</Col>
								<Col lg={3} md={6} sm={12} xs={12}>
									<div className="goal_types yellow">
										<h6>Collaborative</h6>
									</div>
								</Col>
								<Col lg={3} md={6} sm={12} xs={12}>
									<div className="goal_types red">
										<h6>Tech-savvy</h6>
									</div>
								</Col>
								<Col lg={3} md={6} sm={12} xs={12}>
									<div className="goal_types green">
										<h6>Transparent</h6>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="goal_p">
										<p>We want to transparently transcend causes into the virtual world. Bee Justice is a living proof that what happens digitally can have a real tangible effect on the ground.</p>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="projects" className="save_oil">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="heading">
								<h4>Project 1 - Save Soil</h4>
								<p><b>Sadhguru launched</b> the Save Soil movement to save soil from extinction by uniting individuals from all over the world to defend soil health and to assist national leaders in implementing national policies and initiatives to address the <b>catastrophic</b> issue facing humanity & increase the organic content in cultivable soil.</p>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="save_img">
					<Sliderr />
				</div>
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="save_p">
								<p>We are not standing still. Bee Justice will join and support the movement by donating and raising awareness to this global movement.</p>
							</div>
							<div className="mint_button">
								<NavLink className="button" to="/">MINTING SOON</NavLink>
							</div>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="roadMap" className="roadmap_sec">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<div className="heading">
								<h4>Roadmap</h4>
							</div>
							<div className="roadmap_timeline">
								<h4>Phase #1</h4>
							</div>
							<div className="b_background">
								<div className="timeline_listing logo_roaf timeline_phase_1">
									<ul>
										<li className="right">
											<div className="q1">
												<ul className="ul_right">
													<h4>Q1 2022</h4>
													<li> Bee Justice ecosystem creation</li>
													<li>White-Paper start-up</li>
												</ul>
											</div>
										</li>
										<li className="left">
											<div className="q2">
												<ul className="ul_left">
													<h4>Q2 2022</h4>
													<li>Full branding and identity building</li>
													<li>Bee Justice professionals team building</li>
													<li>White-Paper drafting </li>
												</ul>
											</div>
										</li>
										<li className="right mt5_2">
											<div className="q1">
												<ul className="ul_right top_ul_right">
													<h4>Q3 2022</h4>
													<li>Bee Justice team expansion and hiring</li>
													<li>White-Paper v1.0 completion</li>
												</ul>
											</div>
										</li>
										<li className="left mt5_3">
											<div className="q2">
												<ul className="ul_left top_ul_left">
													<h4>End Q3 2022</h4>
													<li>Decision making for first Collection cause</li>
													<li>Art execution begins</li>
													<li>Development of Bee Justice NFT ecosystem continues</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
								<div className="roadmap_timeline">
									<h4 className="purple">Phase #2</h4>
								</div>
								<div className="timeline_listing">
									<ul>
										<li className="right">
											<div className="q1">
												<ul className="ul_right">
													<h4>Mid of Q4 2022</h4>
													<li>Marketing plan and marketing start-up</li>
													<li>Art progress</li>
												</ul>
											</div>
										</li>
										<li className="left mt5-1">
											<div className="q2">
												<ul className="ul_left">
													<h4>Q1 2023</h4>
													<li>Website launch</li>
													<li>Art progress</li>
												</ul>
											</div>
										</li>
										<li className="ground_zero">
											<Nav.Link href="https://mint.beejustice.com/" className="button">Ground Zero 1 – DONATIONS </Nav.Link>
										</li>
										<li className="right mt5_2">
											<div className="q1 mid_q1">
												<ul className="ul_right">
													<h4>Mid of Q2 2023</h4>
													<li>Art completion</li>
													<li>First project NFT collection drop</li>
													<li>Achieving a strong circulation of NFT for higher fundraising</li>
													<li>Bidding for the representative NFT</li>
													<li>Roles’ distribution</li>
												</ul>
											</div>
										</li>
										<li className="left mt5_3">
											<div className="q2 mid_q2">
												<ul className="ul_left">
													<h4>Q3 2023</h4>
													<li>Staking mechanism launch</li>
													<li>Secondary market sales</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
								<div className="roadmap_timeline">
									<h4 className="green">Phase #3</h4>
								</div>
								<div className="timeline_listing timeline_listing_3">
									<ul>
										<li className="left mt5_5">
											<div className="q2">
												<ul className="ul_left">
													<h4>End of Q3 2023</h4>
													<li>Voting mechanism launch</li>
													<li>Community voting on the new collection(s)</li>
													<li>Royal Jelly tokens feeding to generate a new collection</li>
													<li>Artwork design themed for the elected cause</li>
												</ul>
											</div>
										</li>
										<li className="right mt5_4">
											<div className="q1">
												<ul className="ul_right">
													<h4>Q4 2023</h4>
													<li>New collection drops for fundraising</li>
													<li>Achieving a strong circulation of NFT for higher fundraising</li>
													<li>Bidding for the representative NFT</li>
													<li>Roles’ distribution</li>
													<li>Staking mechanism open for new holders</li>
													<li>Secondary market sales for new holders</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>	
						</Col>
					</Row>
				</Container>
			</section>

			<section id="team" className="team_sec">
				<Container fluid>
					<Row className="w-100 mx-auto">
						<Col lg={11} className="mx-auto">
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="heading">
										<h4>Meet the Team</h4>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={12} md={12} sm={12} xs={12}>
									<div className="meet_people">
										<div className="grid-container">
											<div className="grid-item">
												<img className="img-responsive" src={Meet1}/>
											</div>
											<div className="grid-item">	
												<img className="img-responsive" src={Meet2} />
											</div>	
											<div className="grid-item">
												<img className="img-responsive" src={Meet3} />
											</div>	
											<div className="grid-item">
												<img className="img-responsive" src={Meet4} />
											</div>	
											<div className="grid-item">
												<img className="img-responsive" src={Meet5} />
											</div>
											<div className="grid-item">
												<img className="img-responsive" src={Meet6} />
											</div>
											<div className="grid-item">
												<img className="img-responsive" src={Meet7} />
											</div>
											<div className="grid-item">
												<img className="img-responsive" src={Meet8} />
											</div>	
											<div className="grid-item">
												<img className="img-responsive" src={Meet9} />
											</div>
											<div className="grid-item">
												<img className="img-responsive" src={Meet10} />
											</div>	
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
			<Footer />
		</>
	)
}

export default Homepage;