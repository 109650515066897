import React, {useState, useEffect} from 'react';
import Header from '../../Component/Header/header';
import Footer from '../../Component/Footer/footer';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import { product1, product2, product3, product4, productBee, beesSavesoil, Savesoipresent1, Savesoipresent2, Savesoipresent3, Savesoipresent4, Savesoipresent5, Savesoipresent6, Savesoipresent7, Savesoipresent8, Savesoipresent9, Savesoipresent10, Savesoipresent11, Savesoipresent12 } from '../../Asset/imgIndex';
import './product_purchase.scss';

function Mint() {
  const [num, setNum] = useState(0)
  const inNum = () =>{
    setNum(num + 1);
  }
  const decNum = () => {
    setNum(num - 1);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <section className="bee_justice">
        <Container fluid>
          <Row className="w-100 mx-auto">
            <Col lg={11} className="mx-auto">
              <Row>
                <Col lg={12} xs={12}>
                  <div className="second_product">
                    <Row>
                      <Col xl={6} lg={6} sm={12} xs={12}>
                        <div className="dd">
                          <p className="mt-0">Bee Justice has been working to launch its first cause related to earth and soil movement. But we all know well that this world is filled with tragedies and pain that require <b>immediate action</b> to have a direct timely effect.</p>
                        </div>  
                        <div className="about_cause">
                          <p>With this in mind, and under the umbrella of its humanitarian mantle, Bee Justice decided to launch a parallel track. One will be built around causes (Projects), the other around instant relief responses <b>(Ground Zeros - GZ)</b>.</p>
                        </div>
                        <div className="about_cause">
                          <p><b>The earthquake</b> that struck Turkey and northwest Syria was of an epic scale, leaving behind immense destruction, and unimaginable suffering. People in and around the area need urgent care, relief, funds, and equipment. Bee Justice will partake in relief efforts by launching GZ1.</p>
                          <p>GZ1 will be identified by its own NFT, which will be tailor-made to respond to this tragedy.</p>
                          <p>This is not a token to reward your generosity, it is merely a reminder that in our own way, from wherever you may be in the world, you can make a difference, you can help others. NFTs are just a new way of doing that.</p>
                          <p>Collectibles that will stand in testimony to the humanitarian aspect and goodness that we can do online, these unique NFTs will be a limited edition with the hope to make the biggest impact. We’ll have a new NFT for every <b>disaster relief response</b> that we want to commit to.</p>
                          <p>Every NFT bought will see 80% of its profit go to charities. They’re on the ground, trying to provide the necessary assistance, and are in dire need for <b>ANY HELP</b> that you can provide.</p>
                          <p>Let’s put our computers and hearts together, donate through Bee Justice. Let’s show the world what our community can do!</p>
                        </div>
                        {/* <div className="img_product">
                          <Image className="img-fluid" src={Savesoipresent1}/>
                          <Image className="img-fluid" src={Savesoipresent2} />
                          <Image className="img-fluid" src={Savesoipresent3} />
                          <Image className="img-fluid" src={Savesoipresent4} />
                          <Image className="img-fluid" src={Savesoipresent5} />
                          <Image className="img-fluid" src={Savesoipresent6} />
                          <Image className="img-fluid" src={Savesoipresent7} />
                          <Image className="img-fluid" src={Savesoipresent8} />
                          <Image className="img-fluid" src={Savesoipresent9} />
                          <Image className="img-fluid" src={Savesoipresent10} />
                          <Image className="img-fluid" src={Savesoipresent11} />
                          <Image className="img-fluid" src={Savesoipresent12} />
                        </div> */}
                      </Col>
                      <Col xl={6} lg={6} sm={12} xs={12} className="img_bee_nft">
                        <div className="bee_s">
                          <Image className="img-fluid" src={beesSavesoil} />
                          <div className="button_red">
                            <Button variant="danger" className="button_number">500/5000</Button>
                          </div>
                          <div className="numbering_1">
                              <NavLink onClick={decNum} to="#" className="plus_minus">-</NavLink>
                              <Form.Control type="email" placeholder={num} />
                            <NavLink onClick={inNum} to="#" className="plus_minus">+</NavLink>
                              <Button className="button">MINT</Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default Mint; 