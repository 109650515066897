import React from 'react';
import { Container, Nav, Navbar, Image, Row, Col } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import { Logo } from '../../Asset/imgIndex';
import './header.scss';

function Header() {
  return (
        <>
        <Navbar collapseOnSelect expand="lg">
            <Container fluid>
                <Row className="mx-auto w-100">
                    <Col lg={11} className="mx-auto">
                        <div className="bees_nav">
                            <div className="logo_proj">
                                <Navbar.Brand href="/">
                                    <Image className="img-fluid" src={Logo}/>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            </div>    
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="ms-auto nav_links">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <Nav.Link href="#projects">Projects</Nav.Link>
                                    <Nav.Link href="#roadMap">Roadmap</Nav.Link>
                                    <Nav.Link href="#team">Team</Nav.Link>
                                    <Nav.Link href="https://www.dropbox.com/s/yf8xuvwwm2mggas/Bee%20Justice%20WhitePaper%20%28v1.0%29.pdf?dl=0" target="_blank">Whitepaper</Nav.Link>
                                      <Nav.Link className="button" href="https://mint.beejustice.com/"><b>DONATE NOW</b></Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </div>    
                    </Col>
                </Row>    
            </Container>
        </Navbar>
    </>    
  )
}

export default Header;
