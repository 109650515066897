import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Privacy Policy
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-3"> This privacy policy applies to all of the websites offered by Bee Justice. and/or its subsidiaries, including beejustice.com together with all of the associated Services, as defined below (such websites and Services, collectively, the "Site" or the "Sites").</p>
                <p className="mb-3"> This policy describes the types of information we may collect from you or that you may provide when you visit the Sites and our practices for collecting, using, protecting, maintaining and disclosing that data (the "Privacy Policy"). This Privacy Policy applies to information we collect on the Sites and through the registration process for your user account (you "Account") for use of any of the Bee Justice services, including without limitation (i) the purchase, sale, exchange, creation, or modification of certain digital assets and (ii) our online and/or mobile services, and software provided on or in connection with those services (collectively, the "Services").</p> 
                <p className="mb-3"> By using the Sites, you understand that information collected from you in connection with your use of the Sites shall be disclosed to Bee Justice. The Sites may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements or the way in which they may use your data. This Privacy Policy does not apply to those websites. When you leave our Site, we encourage you to read the privacy policy of every website you visit.</p> 
                <p className="mb-3"> <b>Types of Data Collected</b></p> 
                <p className="mb-3"> "Personal Data" is data that identifies you, or from which you could be identified. Personal Data that we may process about you includes:.</p> 
                <li>Any other information you provide to us basic information – such as first and last name, [user name];</li>
                <li>Any other information you provide to us contact details – such as email address, phone number, and shipping address;</li>
                <li>Any other information you provide to us information relating to your account – such as your blockchain address, wallet type, information about sales and purchases and transfers of NFTs;</li>
                <li>Any other information you provide to us information that you provide via your feedback to, and correspondence with, us, such as emails, chat messages, [social media posts] or other communications you send us;</li>
                <p className="mb-3"><li>Any other information you provide to us technical information, such as:</li></p>
                <p><b>1.</b> Information about the device you use to interact with us (including operating system, browser type, browser version and unique device identifiers, internet service provider)</p> 
                <p><b>2.</b> Information from your visits to this website (including the pages of our Sites that you visit, the time spent on those pages, the time and date of your visit, the URLs that you have clicked on, your IP address, the pages that you visited before and after navigating to this website);</p>   
                <p className="mb-3"><b>3.</b> Social media tracking pixels that allow platforms such as Facebook and Google to interact with this website and give feedback on your actions; and diagnostic data;</p>
                <li>Information about your user preferences, favorites, etc.; and</li>
                <p className="mb-3"> <li>Any other information you provide to us.</li></p>
                <p className="mb-3">We may also use aggregated information for various business purposes, but as no individual can be identified from that information that information will not constitute Personal Data.</p>
                <p className="mb-3"><b>How do we obtain your Personal Data?</b></p>
                <p className="mb-3">We gather information about you when you provide it to us, such as when you create an account, or interact with us directly, such as when you use our Sites and Services, or get in touch to provide feedback or request technical support.</p>
                <p className="mb-3">We may also obtain your Personal Data while monitoring our technology tools and services, including the Sites and email communications sent to and from Bee Justice.</p>
                <p className="mb-3">We may combine information we have about you from various sources, including the data that you have provided to us.</p>
                <p className="mb-3"> <b>Cookies and Tracking Technology –</b>We use cookies and similar tracking technologies [including pixel tags] to track the activity on our website and hold certain information.</p> 
                <p className="mb-3"> <b>Third Party Sources –</b> From time to time, we may obtain information about you from the third-party sources such as: </p>
                <li><u>Blockchain Data: </u>We may analyze public blockchain data to ensure parties utilizing our Services are not engaged in illegal or prohibited activity under our terms of use, and to analyze transaction trends for research and development purposes;</li>
                <p className="mb-3"> <li><u>Identity Verification Services: </u>If required by applicable law (such as any know-your-customer requirements), we may obtain information from third-party services using your Personal Data to verify your identity.</li></p>
                <p><b>On what basis do we use your Personal Data?</b></p>
                <p className="mb-3">Data privacy law sets out a number of different reasons on which a company may rely to collect and use your Personal Data. We use your Personal Data for the following reasons:</p>
                <li>To comply with legal and regulatory obligations;</li>
                <li>For legitimate business purposes: for example, using your Personal Data helps us to operate and improve our business and minimize any disruption to the services that we may offer to you. It also allows us to make our Services and communications with you more relevant and personalized to you, and to make your experience of our services more efficient and effective;</li>
                <li>Because you have given your consent: at times, we may ask for your consent to allow us to use your Personal Data for one or more purposes. See the "Your Rights" section for information about the rights that you have if we process your Personal Data on the basis;</li>
                <li>To take steps before entering into a contract, or performing a contract to which you are a party: we may need to process your Personal Data to provide a product or service that you request and our ability to do so will be impaired if we do not process your Personal Data; and</li>
                <p className="mb-3"><li>For the establishment, exercise or defense of legal claims or proceedings.</li></p>
                <b>Data Use</b>
                <p className="mb-3">We may use your Personal Data for the following purposes:</p>
                <li>To fulfill the purpose for which you provide it;</li>
                <li>To provide you with the Services;</li>
                <li>To support our operations and activities and improve our Services;</li>
                <li>To provide customer care and support;</li>
                <li>To provide relevant content and resources;</li>
<li>To advertise our products on the Sites or on third party sites;</li>
<li>To enable the marketing of third party products on the Sites;</li>
<li>To provide and maintain the Sites;</li>
<li>To notify you about changes to our Sites;</li>
<li>To allow you to participate in interactive features of our Sites when you choose to do so;</li>
<li>To provide analysis or valuable information so that we can improve the Sites;</li>
<li>To monitor the usage of the Sites;</li>
<li>To detect, prevent and address technical issues;</li>
<li>To detect and prevent fraud and other unlawful or harmful activity;</li>
<li> To the extent relevant to a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Bee Justice’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding; and</li>
<li> To comply with our legal, regulatory and other obligations, and to communicate with law enforcement and other authorities, in each case in any jurisdiction; and</li>
<p className="mb-3"><li>For any other purpose disclosed by us when you provide the information.</li></p>
<p>We may also disclose Personal Data to:</p>
<li>Comply with a legal obligation, including to comply with any court order, law, or legal process, or to respond to any government or regulatory request in any jurisdiction;</li> 
<li>Protect and defend our rights or property;</li>
<li>Prevent or investigate possible wrongdoing in connection with the Site, including violations of our policies and unlawful or harmful activities;</li>
<li>Protect the personal safety of users of the Sites or the public; or</li>
<p className="mb-3"><li>Protect against legal liability.</li> </p>
<b>Transfer of Data</b>
<p className="mb-3">Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and applicable data protection laws. No transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place, including the security of your data and other Personal Data.
</p>
<b>Disclosure of Information </b>
<p className="mb-3">We may disclose Personal Data that we collect or you provide as described in this Privacy Policy:</p>
<li>To our subsidiaries and affiliates;</li>
<li>To selected agents and suppliers (including those who provide us with technology services such as hosting and technical support); or</li>
<p className="mb-3"> <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Bee Justice’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Bee Justice about our Site users is among the assets transferred;
We may employ third party companies and individuals to perform web-related services or to assist us in analyzing, improving and optimizing how our Site is used.
</li></p>
<b>Security of Data</b>
<p className="mb-3">The security of your data is important to us. We use a range of measures to keep your information safe and secure which may include encryption and other forms of security. However, please remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
</p>
<b>Data retention </b>
<p className="mb-3">We will always keep your personal information for the period required by law. We will also keep your personal information where we need to do so in connection with legal action or an investigation. Otherwise, we keep your personal information:
</p>
<li>For as long as needed to provide you with access to services you have requested;</li>
<li>Where you have contacted us with a question or request, for as long as necessary to allow us to respond your question or request;</li>
<p className="mb-3"><li>For as long as it is necessary and relevant for our business; and Your rights
</li></p>
<b>Children’s Privacy </b>
<p className="mb-3">Our Service does not address, and is not intended for, anyone under the age of 16.
We do not knowingly collect personally identifiable information from anyone under the age of 16. If you are under the age of 16, do not use, or provide any information on, the Sites. If you believe that we have been provided with any Personal Data from anyone under the age of 16, please contact us at contact information included at the end of this policy. If we become aware that we have collected Personal Data from anyone under the age of 16, we take steps to remove that information from our servers.
</p>
<b>Updates to this Privacy Policy</b>
<p className="mb-3">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Sites, prior to the change becoming effective and update the "effective date" at the bottom of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Sites and this Privacy Policy to check for any changes.
</p>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className="button_close">Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;