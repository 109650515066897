import React from 'react';
import Header from '../../Component/Header/header';
import { ErrorPage } from '../../Asset/imgIndex';
import Footer from '../../Component/Footer/footer';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './error.scss';


function Error() {
    return (
        <>
            <Header />
            <section className="Error">
                <Container fluid>
                    <Row className="mx-auto w-100">
                        <Col lg={11} className="mx-auto">
                            <div className="error">
                                <Image className="img-fluid" src={ErrorPage}/>
                            </div>      
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default Error;